<template>
  <div class="flex">
    <div class="p-2 border-r border-gray-400 flex items-start flex-shrink-0">
      <img
        class="w-8 inline-block"
        :src="
          require(`@/assets/${
            ROLES[name] ? ROLES[name].icon : 'logo-unknown.png'
          }`)
        "
        alt="Product logo"
      />
    </div>
    <div class="p-2">
      <div class="font-bold mb-5">
        {{ ROLES[name] ? $t(ROLES[name].key) : name }}
      </div>
      <span class="flow-root">
        <label class="ivu-form-item-label">
          {{ $t("EXPIRATION") }}
        </label>
      </span>
      <span v-show="!editExpirationDate">
        <span :class="{ 'text-red-600': expired }">
          {{
            !roleExpirationDate ||
            new Date(roleExpirationDate) > new Date("2999")
              ? $t("NEVER")
              : new Date(roleExpirationDate).toLocaleString()
          }}
        </span>
        <Button
          v-if="edit"
          type="text"
          size="small"
          @click="editExpirationDate = true"
        >
          <fa icon="pen" />
        </Button>
      </span>
      <Form
        v-show="editExpirationDate"
        @submit.native.prevent="setExpirationDate"
        class="flex items-center"
        :disabled="expirationDateLoading"
      >
        <FormItem class="m-0 flex-grow">
          <DatePicker
            type="datetime"
            v-model="newExpirationDate"
            :placeholder="$t('EXPIRATION_DATE')"
            :options="datePickerOptions"
            format="dd/MM/yyyy HH:mm"
          />
        </FormItem>
        <Button
          type="text"
          html-type="submit"
          size="small"
          :disabled="dirtyExpirationDate"
          :loading="expirationDateLoading"
        >
          <fa icon="save" />
        </Button>
        <Button type="text" size="small" @click="cancelEditExpirationDate">
          <fa icon="times" />
        </Button>
      </Form>
      <div v-show="ROLES[name].features.length" class="flow-root mt-5">
        <label class="ivu-form-item-label">{{ $t("FEATURES") }}</label>
      </div>
      <ul>
        <li v-for="feature in ROLES[name].features" :key="feature">
          <i-switch
            v-if="edit"
            class="my-1"
            true-color="#13ce66"
            false-color="#ff4949"
            :value="features.includes(feature)"
            :loading="featureLoading === feature"
            @on-change="state => toggleFeature(feature, state)"
          />
          <fa
            v-else
            :icon="features.includes(feature) ? 'check' : 'times'"
            :class="
              features.includes(feature) ? 'text-green-500' : 'text-red-600'
            "
          ></fa>
          <span class="pl-2">{{ $t(FEATURES[feature]) }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import { ROLES, FEATURES } from "@/assetsMapping.const.js";

export default {
  props: {
    id: { type: String },
    name: { type: String, required: true },
    expirationDate: String,
    features: { type: Array, required: true },
    edit: { type: Boolean, default: false }
  },
  inject: { organization: { default: {} } },
  data: ({ expirationDate }) => ({
    featureLoading: "",
    editExpirationDate: false,
    expirationDateLoading: false,
    newExpirationDate:
      expirationDate && new Date(expirationDate) < new Date("2999")
        ? new Date(expirationDate)
        : ""
  }),
  computed: {
    expired: ({ expirationDate }) =>
      expirationDate ? new Date(expirationDate) < new Date() : false,
    datePickerOptions: ({ $i18n }) => {
      const now = new Date();
      const oneDayAgo = new Date(new Date().setDate(now.getDate() - 1));
      const inOneWeek = new Date(new Date().setDate(now.getDate() + 7));
      const inOneMonth = new Date(new Date().setMonth(now.getMonth() + 1));
      const inOneYear = new Date(new Date().setFullYear(now.getFullYear() + 1));

      return {
        disabledDate: date => date < oneDayAgo,
        shortcuts: [
          { text: $i18n.t("ONE_WEEK"), value: () => inOneWeek },
          { text: $i18n.t("ONE_MONTH"), value: () => inOneMonth },
          { text: $i18n.t("ONE_YEAR"), value: () => inOneYear },
          { text: $i18n.t("NEVER"), value: () => "" }
        ]
      };
    },
    // we are using the expiration date from context
    // TODO: stop mixing props and context!
    roleExpirationDate: ({
      id,
      expirationDate,
      organization: { roles = [] }
    }) => roles.find(role => role.id === id)?.expirationDate ?? expirationDate,
    dirtyExpirationDate: ({ roleExpirationDate, newExpirationDate }) => {
      const normalizedRoleExpirationDate =
        roleExpirationDate && new Date(roleExpirationDate) < new Date("2999")
          ? new Date(roleExpirationDate)
          : 0;
      const normalizedNewExpirationDate = newExpirationDate
        ? new Date(newExpirationDate)
        : 0;

      return +normalizedRoleExpirationDate === +normalizedNewExpirationDate;
    },
    ROLES: () => ROLES,
    FEATURES: () => FEATURES
  },
  methods: {
    toggleFeature(feature, grant) {
      this.featureLoading = feature;
      const { id } = this;

      this.$apollo
        .mutate({
          mutation: gql`
            mutation($id: ID!, $grant: Boolean!, $features: [String!]!) {
              alterRoleFeatures(id: $id, grant: $grant, features: $features) {
                id
                features
              }
            }
          `,
          variables: { id, grant, features: [feature] }
        })
        .catch(({ graphQLErrors, networkError }) =>
          this.$Notice.error({
            title: this.$t("ERROR"),
            desc: JSON.stringify(networkError || graphQLErrors),
            duration: 0
          })
        )
        .finally(() => (this.featureLoading = ""));
    },
    cancelEditExpirationDate() {
      const { roleExpirationDate } = this;
      Object.assign(this.$data, {
        newExpirationDate:
          roleExpirationDate && new Date(roleExpirationDate) < new Date("2999")
            ? new Date(roleExpirationDate)
            : "",
        editExpirationDate: false
      });
    },
    setExpirationDate() {
      this.expirationDateLoading = true;
      const { id, newExpirationDate, organization: { roles } = {} } = this;

      this.$apollo
        .mutate({
          mutation: gql`
            mutation($id: ID!, $expirationDate: DateTime!) {
              setRoleExpiration(id: $id, expirationDate: $expirationDate) {
                id
                expirationDate
              }
            }
          `,
          variables: {
            id,
            expirationDate: new Date(newExpirationDate || "3000").toISOString()
          }
        })
        .catch(({ graphQLErrors, networkError }) =>
          this.$Notice.error({
            title: this.$t("ERROR"),
            desc: JSON.stringify(networkError || graphQLErrors),
            duration: 0
          })
        )
        .then(({ data: { setRoleExpiration: { expirationDate } = {} } }) => {
          // we are forced to manually refresh context provided organization object
          // because it's not auto refreshed from Apollo cache
          // TODO: solve that!
          const roleIndex = roles.findIndex(role => role.id === id);
          roles[roleIndex].expirationDate = expirationDate;
          this.newExpirationDate =
            new Date(expirationDate) < new Date("2999")
              ? new Date(expirationDate)
              : "";
        })
        .finally(() =>
          Object.assign(this.$data, {
            expirationDateLoading: false,
            editExpirationDate: false
          })
        );
    }
  }
};
</script>

